body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #071428;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.image {
  animation: rotation 8s infinite linear;
}

@media only screen and (max-width: 1000px) {
  .panel-left {
    width: 100vw !important;
    height: auto !important;
    margin: 50px 0px;
  }
  .panel-right {
    width: 100vw !important;
    height: auto !important;
    margin: 30px 0px;
  }
  .panel-right div {
    width: 80% !important;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
